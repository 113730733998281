import axios from 'axios';
import _ from 'lodash';
import config from '../config';

const API = {};

const bns_base = config.bns_api_base;

// set axios defaults headers for all requests
axios.defaults.headers.common['Content-Type'] = 'application/json';

API.get_version = () => {
    return axios.get(bns_base + '/version');
};

API.skipRefresh = (url) => {
    const list = [bns_base + '/auth/login', bns_base + '/auth/refreshToken', bns_base + '/auth/logout'];
    return list.includes(url);
};

API.get_menu_item = async (role_id) => {
    if (role_id === 0)
        return {
            data: {
                navigation: { visible: true, url: null },
                list: { visible: true, url: null },
                list_bns_order: { visible: true, url: '/list/order' },
                list_bns_bot: { visible: true, url: '/list/bot' },
                list_bns_buyer_account: { visible: true, url: '/list/buyer_account' },
                list_bns_job: { visible: true, url: '/list/job' },
                list_test_mode: { visible: true, url: '/list/test_mode' },
                list_merchant_type: { visible: true, url: '/list/merchant_type' },
                list_virtual_machine: { visible: true, url: '/list/virtual_machine' },
                list_proxy: { visible: true, url: '/list/proxy' },
                list_default_answers: { visible: true, url: '/list/default_answers' },
                list_default_answer: { visible: true, url: '/list/default_answer/list' },
                settings: { visible: true, url: null },
                settings_profile: { visible: true, url: '/settings/profile' },
                settings_adminuser: { visible: true, url: '/settings/admins' },
                settings_config: { visible: true, url: '/settings/config' },
                settings_system_health: { visible: true, url: '/settings/system_health' },
            },
        };
    else if (role_id === 1)
        return {
            data: {
                navigation: { visible: true, url: null },
                list: { visible: true, url: null },
                list_bns_order: { visible: true, url: '/list/order' },
                list_bns_bot: { visible: true, url: '/list/bot' },
                list_bns_buyer_account: { visible: true, url: '/list/buyer_account' },
                list_bns_job: { visible: true, url: '/list/job' },
                list_test_mode: { visible: true, url: '/list/test_mode' },
                list_merchant_type: { visible: true, url: '/list/merchant_type' },
                list_virtual_machine: { visible: true, url: '/list/virtual_machine' },
                list_proxy: { visible: true, url: '/list/proxy' },
                list_default_answer: { visible: true, url: '/list/default_answer/list' },
                list_default_answers: { visible: true, url: '/list/default_answers' },
                settings: { visible: true, url: null },
                settings_profile: { visible: true, url: '/settings/profile' },
                settings_adminuser: { visible: false, url: '/settings/admins' },
                settings_config: { visible: true, url: '/settings/config' },
                settings_system_health: { visible: true, url: '/settings/system_health' },
            },
        };
    else
        return {
            data: {
                navigation: { visible: true, url: null },
                list: { visible: true, url: null },
                list_bns_order: { visible: true, url: '/list/order' },
                list_bns_bot: { visible: true, url: '/list/bot' },
                list_bns_buyer_account: { visible: true, url: '/list/buyer_account' },
                list_bns_job: { visible: true, url: '/list/job' },
                list_test_mode: { visible: true, url: '/list/test_mode' },
                list_merchant_type: { visible: true, url: '/list/merchant_type' },
                list_virtual_machine: { visible: true, url: '/list/virtual_machine' },
                list_proxy: { visible: true, url: '/list/proxy' },
                list_default_answer: { visible: true, url: '/list/default_answer/list' },
                list_default_answers: { visible: true, url: '/list/default_answers' },
                settings: { visible: true, url: null },
                settings_profile: { visible: true, url: '/settings/profile' },
                settings_adminuser: { visible: false, url: '/settings/admins' },
                settings_config: { visible: false, url: '/settings/config' },
                settings_system_health: { visible: false, url: '/settings/system_health' },
            },
        };    
};

// BNS Admin APIs START

API.create_admin = async (data) => {
    // use axios
    return axios
        .post(bns_base + '/admin/create', data)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.login = async (email, password) => {
    return axios.post(bns_base + '/auth/login', { email, password });
};

API.get_admin_all = async () => {
    //use axios
    return axios
        .get(bns_base + '/admin')
        .then((response) => response.data)
        .catch((err) => console.log(err));
    // return await fetch(bns_base + '/admin')
    //     .then((response) => response.data)
    //     .catch((err) => console.log(err));
};

API.get_admin_by_criteria = async (pageIndex, itemsPerPage, sortBy, orderBy, keyword) => {
    let params = {
        pageIndex,
        itemsPerPage,
        sortBy,
        orderBy, // DESC | ASC
        keyword,
    };
    // filter empty by lodash
    params = _.pickBy(params, _.identity);

    // params = Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);

    let request_link = bns_base + '/admin';
    // use axios
    return await axios
        .get(request_link, { params })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.get_admin = async (admin_id) => {
    //use axios
    return axios
        .get(bns_base + '/admin/' + admin_id)
        .then((response) => response.data)
        .catch((err) => console.log(err));

    // return await fetch(bns_base + '/admin/' + admin_id)
    //     .then((response) => response.data)
    //     .catch((err) => console.log(err));
};

API.delete_admin = async (admin_id) => {
    //use axios
    return axios
        .delete(bns_base + '/admin/' + admin_id)
        .then((response) => response.data)
        .catch((err) => console.log(err));

    // return await fetch(bns_base + '/admin/' + admin_id, {
    //     method: 'DELETE',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    // })
    //     .then((response) => response.data)
    //     .catch((err) => console.log(err));
};

API.update_adminInfo = async (admin_id, username, role, merchantType) => {
    //use axios
    return axios
        .patch(bns_base + '/admin/' + admin_id, { username, role, merchantType })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.get_config = async () => {
    //use axios
    return axios
        .get(bns_base + '/setting/')
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.update_apiKey = async (value) => {
    //use axios
    console.log(value);
    return axios
        .patch(bns_base + '/setting/api-key', { name: 'api-key', value })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.update_webhookUrl = async (value) => {
    //use axios
    console.log(value);
    return axios
        .patch(bns_base + '/setting/webhookUrl', { name: 'webhookUrl', value })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

// BNS Admin APIs END

API.refreshToken = async (refreshToken) => {
    return axios.post(bns_base + '/auth/refreshToken', { refreshToken });
};

API.revokeToken = async (refreshToken) => {
    return axios.post(bns_base + '/auth/logout', { refreshToken });
};

API.get_admin_me = async () => {
    return axios.get(bns_base + '/admin/me');
};

API.update_adminInfo_me = async (id, username) => {
    return axios.patch(bns_base + '/admin/' + id, {
        username,
    });
};

API.update_password = async (id, password) => {
    return axios.patch(bns_base + '/admin/' + id, {
        password,
    });
};

API.get_role_permitted = async () => {
    // return axios.get(base + '/role/permitted');
    return {
        data: [
            {
                role_id: 0,
                name: 'Admin',
                level: 0,
                is_superadmin: 1,
                created_at: '2021-08-27T07:39:22.000Z',
                updated_at: '2021-09-09T08:12:08.000Z',
            },
            {
                role_id: 1,
                name: 'Operator',
                level: 1,
                is_superadmin: 0,
                created_at: '2021-08-30T01:01:43.000Z',
                updated_at: '2021-09-09T08:12:08.000Z',
            },
            {
                role_id: 2,
                name: 'Read-only',
                level: 2,
                is_superadmin: 0,
                created_at: '2024-07-18T01:01:43.000Z',
                updated_at: '2024-07-18T01:12:08.000Z',
            },
        ],
    };
};

// BNS

API.get_order_no_criteria = async () => {
    //use axios
    return await axios
        .get(bns_base + '/order')
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.get_order_criteria = async (
    ManualCheck,
    RetryCheckoutOnly,
    isPortal,
    pageIndex,
    itemsPerPage,
    sortBy,
    orderBy,
    orderState,
    keyword,
    searchType
) => {
    let params = {
        isPortal: isPortal ? 1 : 0,
        pageIndex,
        itemsPerPage,
        sortBy,
        orderBy, // DESC | ASC
        // keyword,
        orderState: RetryCheckoutOnly ? 'RetryCheckout' : orderState,
        isManualCheck: ManualCheck ? 1 : 0,
    };

    console.log(searchType, keyword);

    params[searchType] = keyword;

    // filter empty by lodash
    params = _.pickBy(params, _.identity);

    // params = Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);

    let request_link = bns_base + '/order';
    // use axios
    return await axios
        .get(request_link, { params })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.update_trackingNumber = async (orderId, trackingNumber) => {
    // use axios
    return await axios
        .post(bns_base + '/order/updateTrackingNumber', {
            orderId,
            trackingNumber,
        })
        .then((response) => response.data)
        .catch((err) => console.log(err));

    // return await fetch(bns_base + '/order/updateTrackingNumber', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ orderId, trackingNumber }),
    // })
    //     .then((response) => response.data)
    //     .catch((err) => console.log(err));
};

API.cancel_order = async (order_id) => {
    return await axios
        .post(bns_base + '/order/cancel-order/' + order_id)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.check_shippingNumber = async () => {
    return await axios
        .get(bns_base + '/job-manager/checkShipping')
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.refresh_single_order = async (order_id) => {
    // use axios
    return await axios
        .get(bns_base + '/order/' + order_id)
        .then((response) => response.data)
        .catch((err) => console.log(err));

    // return await fetch(bns_base + '/order/' + order_id)
    //     .then((response) => response.data)
    //     .catch((err) => console.log(err));
};

API.get_buyer_account_all = async () => {
    // use axios
    return await axios
        .get(bns_base + '/ecommerce-account?itemsPerPage=5&pageIndex=0')
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.get_buyer_account_by_criteria = async (pageIndex, itemsPerPage, sortBy, orderBy, keyword) => {
    let params = {
        pageIndex,
        itemsPerPage,
        sortBy,
        orderBy, // DESC | ASC
        keyword,
    };
    // filter empty by lodash
    params = _.pickBy(params, _.identity);

    // params = Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);

    let request_link = bns_base + '/ecommerce-account';
    // use axios
    return await axios
        .get(request_link, { params })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.create_buyer_account = async (data) => {
    // use axios
    return await axios
        .post(bns_base + '/ecommerce-account', data)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.update_buyer_account = async (account_id, data) => {
    // use axios
    return await axios
        .patch(bns_base + '/ecommerce-account/' + account_id, data)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.delete_buyer_account = async (account_id) => {
    // use axios
    return await axios
        .delete(bns_base + '/ecommerce-account/' + account_id)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.get_all_bot_order = async () => {
    let request_link = bns_base + '/order?orderState=processing';
    let itemsCount = await fetch(request_link)
        .then((response) => response.data)
        .catch((err) => console.log(err));
    itemsCount = itemsCount.data.totalItems;
    // use axios
    return await axios
        .get(request_link + '&itemsPerPage=' + itemsCount)
        .then((response) => response.data)
        .catch((err) => console.log(err));
    // return await fetch(request_link + '&itemsPerPage=' + itemsCount)
    //     .then((response) => response.data)
    //     .catch((err) => console.log(err));
};

API.send_bot_command = async (bot_id, command) => {
    // use axios
    return await axios
        .post(bns_base + command, {
            bot_id,
            command,
        })
        .then((response) => response.data)
        .catch((err) => console.log(err));
    // return await fetch(bns_base + command, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify([bot_id, command]),
    // })
    //     .then((response) => response.data)
    //     .catch((err) => console.log(err));
};

API.get_job_list = async (isTestJob, pageIndex, itemsPerPage, sortBy, orderBy, keyword, genre, type, status) => {
    let params = {
        isTestJob: isTestJob ? 1 : 0,
        pageIndex,
        itemsPerPage,
        sortBy,
        orderBy, // DESC | ASC
    };

    if (type != 'any') {
        params['type'] = type;
    }

    if (status != 'any') {
        params['status'] = status;
    }

    if (genre == 'all') {
        params['keyword'] = keyword;
    } else {
        params[genre] = keyword;
    }

    // filter empty by lodash
    params = _.pickBy(params, _.identity);
    let request_link = bns_base + '/job-manager';
    // use axios
    return await axios
        .get(request_link, { params })
        .then((response) => response.data)
        .catch((err) => console.log(err));
    // return await fetch(request_link)
    //     .then((response) => response.data)
    //     .catch((err) => console.log(err));
};

API.get_worker_health = async () => {
    //pageIndex, itemsPerPage, sortBy, orderBy) => {
    return await axios
        .get(bns_base + '/job-manager/get-Health')
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.get_pending_job_count = async () => {
    return await axios
        .get(bns_base + '/job-manager/noOfPendingJobs')
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.get_active_workers_count = async () => {
    return await axios
        .get(bns_base + '/ecommerce-account/active-workers')
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.test_mode_check_stock = async (url, plugin) => {
    let data = {};
    if (plugin == undefined) {
        data = {
            merchantType: 'rakuten',
            version: '0',
            url: url,
        };
    } else {
        data = {
            merchantType: plugin.merchantType,
            version: plugin.version.toString(),
            url: url,
        };
    }

    let path = `/job-manager/checkStockForCms`;

    return await axios
        .post(bns_base + path, data)
        .then((response) => response)
        .catch((err) => err);
};

// API.test_mode_addcart = async (url, qty, plugin) => {
API.test_mode_addcart = async (url, plugin, answer) => {
    let path = `/job-manager/addCartForCms`;
    let data = {};

    if (plugin == undefined) {
        data = {
            merchantType: 'rakuten',
            version: '0',
            url: url,
            answers: answer,
        };
    } else {
        data = {
            merchantType: plugin.merchantType,
            version: plugin.version.toString(),
            url: url,
            answers: answer,
        };
    }

    return await axios
        .post(bns_base + path, data)
        .then((response) => response)
        .catch((err) => err);
};

API.get_virtual_machine = async () => {
    return await axios
        .get(bns_base + '/job-manager/find-worker-heartbeat')
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.account_to_manual = async (account_id) => {
    return await axios
        .get(bns_base + '/ecommerce-account/pause-to-manual/' + account_id)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.account_to_bot = async (account_id) => {
    return await axios
        .get(bns_base + '/ecommerce-account/resume-to-robot/' + account_id)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.order_to_manual = async (order_id) => {
    return await axios
        .get(bns_base + '/order/pause-to-manual/' + order_id)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.order_to_bot = async (order_id) => {
    return await axios
        .get(bns_base + '/order/resume-to-robot/' + order_id)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.get_image = async (url) => {
    return await axios
        .get(url, { responseType: 'blob' })
        .then((response) => window.URL.createObjectURL(response.data))
        .catch((err) => console.log(err));
};

API.get_video = async (url) => {
    return await axios
        .get(url, { responseType: 'blob' })
        .then((response) => window.URL.createObjectURL(response.data))
        .catch((err) => console.log(err));
};

API.open_image_in_blank_page = async (url) => {
    return await axios
        .get(url, { responseType: 'blob' }) // FETCH BLOB FROM IT
        .then((response) => {
            var _url = window.URL.createObjectURL(response.data);
            window.open(_url, '_blank').focus();
        })
        .catch((err) => {
            console.log(err);
        });
};

// Jun 29 START

API.update_testingUrls = async (url_str) => {
    return await axios
        .patch(bns_base + '/setting/testingUrl/', { value: url_str })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.update_shippingCallback = async (value) => {
    return await axios
        .patch(bns_base + '/setting/shippingCallBack', { value })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.retry_checkout = async (obj_arr) => {
    return await axios
        .post(bns_base + '/job-manager/retryCheckOut', obj_arr)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.upload_plugin = async (data) => {
    return await axios({
        method: 'post',
        url: bns_base + '/plugin-loader/upload',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' },
    })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.get_merchant_types = async (pageIndex, itemsPerPage, sortBy, orderBy) => {
    let params = {
        pageIndex,
        itemsPerPage,
        sortBy,
        orderBy, // DESC | ASC
    };

    params = _.pickBy(params, _.identity);
    let request_link = bns_base + '/plugin-loader';

    return await axios
        .get(request_link, { params })
        .then((response) => response.data)
        .catch((err) => err);
};

API.create_merchant_type = async (data) => {
    return await axios
        .post(bns_base + '/plugin-loader', data)
        .then((response) => response.data)
        .catch((err) => err);
};

API.update_plugin_file = async (id, fileId) => {
    // to-do
    return await axios
        .patch(bns_base + '/plugin-loader/' + id, { fileId })
        .then((response) => response.data)
        .catch((err) => err);
};

API.confirm_plugin = async (id, fileId) => {
    // to-do
    return await axios
        .patch(bns_base + '/plugin-loader/' + id, { fileId, status: 'confirmed' })
        .then((response) => response.data)
        .catch((err) => err);
};

// Jun 29 END

// Jul 11 START
API.get_plugin_versions = async () => {
    return await axios
        .get(bns_base + '/plugin-loader/findVersion')
        .then((response) => response.data)
        .catch((err) => err);
};
API.find_merchant_types = async () => {
    return await axios
        .get(bns_base + '/plugin-loader/findMerchantType')
        .then((response) => response.data)
        .catch((err) => err);
};

API.update_plugin_test_status = async (id, fileId, testModeStatus) => {
    // to-do
    return await axios
        .patch(bns_base + '/plugin-loader/' + id, { fileId, testModeStatus })
        .then((response) => response.data)
        .catch((err) => err);
};
// Jul 11 END

// Jul 27 START

API.plugin_rollback = async (id, info) => {
    return await axios
        .patch(bns_base + '/plugin-loader/rollback/' + id, info)
        .then((response) => response.data)
        .catch((err) => err);
};

API.plugin_cancel = async (id) => {
    return await axios
        .patch(bns_base + '/plugin-loader/' + id, { status: 'error' })
        .then((response) => response.data)
        .catch((err) => err);
};

API.get_all_plugins = async () => {
    return await axios
        .get(bns_base + '/plugin-loader/findAllForTestMode')
        .then((response) => response.data)
        .catch((err) => err);
};

API.update_merchant_type = async (id, data) => {
    return await axios
        .patch(bns_base + '/plugin-loader/' + id, data)
        .then((response) => response.data)
        .catch((err) => err);
};

// Jul 27 END

// Aug 14 START
API.get_proxy_list = async (pageIndex, itemsPerPage, sortBy, orderBy) => {
    let params = {
        pageIndex,
        itemsPerPage,
        sortBy,
        orderBy, // DESC | ASC
        // keyword,
    };
    // filter empty by lodash
    params = _.pickBy(params, _.identity);

    return await axios
        .get(bns_base + '/proxy', { params })
        .then((response) => response.data)
        .catch((err) => err);
};

API.create_proxy = async (data) => {
    return await axios
        .post(bns_base + '/proxy/', data)
        .then((response) => response.data)
        .catch((err) => err);
};

API.update_proxy = async (id, data) => {
    return await axios
        .patch(bns_base + `/proxy/${id}`, data)
        .then((response) => response.data)
        .catch((err) => err);
};

API.delete_proxy = async (id) => {
    return await axios
        .delete(bns_base + `/proxy/${id}`)
        .then((response) => response.data)
        .catch((err) => err);
};

API.get_all_confirmed_plugins = async () => {
    return await axios
        .get(bns_base + '/plugin-loader/findConfirmedMerchantType')
        .then((response) => response.data)
        .catch((err) => err);
};

API.check_layout = async () => {
    return await axios
        .get(bns_base + '/job-manager/checkLayout')
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.get_csv = async (order_type, merchant_type, from, to) => {
    return await axios
        .get(bns_base + `/order/getCsv/${order_type}/${merchant_type}/${from}/${to}`)
        .then((response) => response)
        .catch((err) => console.log(err));
};

// Aug 14 END

// oct 4 start

API.save_default_answer = async (url, questionObject, answer) => {
    return await axios
        .post(bns_base + `/defaultanswer`, { url, questionObject, answer })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.list_default_answers = async (pageIndex, itemsPerPage, sortBy, orderBy, keyword) => {
    let params = {
        pageIndex,
        itemsPerPage,
        sortBy,
        orderBy, // DESC | ASC
        keyword,
    };

    params = _.pickBy(params, _.identity);

    return await axios
        .get(bns_base + `/defaultanswer`, { params })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.get_default_answer = async (url) => {
    return await axios
        .get(bns_base + `/defaultanswer/list?url=${url}`)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.delete_default_answer = async (id) => {
    return await axios
        .delete(bns_base + `/defaultanswer/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

// oct 4 end

// Dec 07 start

API.update_nonOfficeHours = async (str) => {
    return await axios
        .post(bns_base + '/setting/nonOfficeHours/', { nonOfficeHours: str })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};
// Dec 07 end

// Jan 15 start

API.order_findSwitchableAccounts = async (accountId) => {
    return await axios
        .get(bns_base + '/ecommerce-account/find-Avalible-account?accountId=' + accountId)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.orderRefSwitchAccount = async (orderReferenceId, accountId) => {
    return await axios
        .post(bns_base + '/order/orderReferenceSwitchAccount/', { orderReferenceId, accountId })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};
// Jan 15 end

// Jan 27 2024

API.jobCancel = async (jobId) => {
    return await axios
        .get(bns_base + '/job-manager/jobCancel?jobManagerRefId=' + jobId)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

// 05 Feb 2024
API.saveShippingNumber = async (orderId, shippingNumber) => {
    return await axios
        .post(bns_base + '/order/updateShippingNumber', { orderId, shippingNumber })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

// 19 Mar 2024 - Order Update
API.updateManualChecked = async (orderReference_id) => {
    return await axios
        .post(bns_base + '/order/updateManualChecked', { orderReference_id })
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

// 02 Apr 2024 - Buyer Account Add Other Information Field
API.listAllMerchantTypeOtherInformation = async () => {
    return await axios
        .get(bns_base + '/plugin-loader/findOtherInformationList')
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.getTargetProxy = async (merchant) => {
    return await axios
        .get(bns_base + `/proxy/getByType?merchantType=${merchant}`)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

API.getTargetAccount = async (id) => {
    return await axios
        .get(bns_base + `/ecommerce-account/${id}`)
        .then((response) => response.data)
        .catch((err) => console.log(err));
};

export default API;
